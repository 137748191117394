import React, { Component } from "react";

interface Props {}
interface State {}

export class Footer extends Component<Props, State> {
  render() {
    return (
      <>
        <section className="board-section">
          <div className="container">
            <div className="company-logo">
              <img src="img/company-logo/aristocrat.png" />
              <img src="img/company-logo/egt.png" />
              <img src="img/company-logo/novomatic.png" />
              <img src="img/company-logo/nextgen.png" />
              <img src="img/company-logo/greentube.png" />
              <img src="img/company-logo/endorphina.png" />
              <img src="img/company-logo/megajack.png" />
              <img src="img/company-logo/unicum.png" />
              <img src="img/company-logo/alpsgames.png" />
              <img src="img/company-logo/brandgames.png" />
              <img src="img/company-logo/qilin.png" />
              <img src="img/company-logo/babochki.png" />
              <img src="img/company-logo/igrosoft.png" />
              <img src="img/company-logo/belatra.png" />
            </div>
          </div>
        </section>
        <footer className="footer-section">
          <div className="container">
            <span className="copyright">
              ⓒ Copyright SeveN SLot 2018 All rights received.
            </span>
          </div>
        </footer>
      </>
    );
  }
}
