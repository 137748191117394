import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum noteView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}


export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)``;

const CustomTableCell2 = styled(TableCell)``;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className="ng-scope" key={row._id}>
        <CustomTableCell2 className="width12 text-center ng-binding">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_read_note(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_read_note(row._id);
            setOpen(!open);
          }}
          className="text-left width70"
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="width15 text-center ng-binding"
        >
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="width15 text-center ng-binding"
        >
          {row.isRead === "y" ? "확인" : "미확인"}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.text }}></div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClosePopup: () => any;
  handleChange: (viewName: string) => any;
  handleClose: () => any;

  note: any;
}

interface State {
  helps: any;
  mode: string;
  regdate: string;
  title: string;
  contents: string;
  isRead: boolean;
  text: string;
  index: string;
  status: string;
}

export class Note extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      mode: noteView.list,
      title: "",
      contents: "",
      regdate: "",
      isRead: false,
      text: "",
      index: "",
      status: "",
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  render() {
    const RenderList = () => {
      if (this.state.mode !== noteView.list) {
        return <></>;
      }
      return (
        <>
          <table
            className="table table-hover table-bordered"
            style={{ textAlign: "right", color: "white", fontSize: "14px" }}
          >
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "20%" }}>번호</th>
                <th style={{ textAlign: "center", width: "40%" }}>제목</th>
                <th style={{ textAlign: "center", width: "20%" }}>작성일</th>
                <th style={{ textAlign: "center", width: "20%" }}>상태</th>
              </tr>

              {this.props.note.map((info: any, index: any) => (
                <>
                  <tr>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "left", color: "#337ab7" }}>
                      <a
                        style={{ color: "#337ab7" }}
                        onClick={() => {
                          service.do_read_note(info._id);
                          this.setState({
                            title: info.title,
                            contents: info.contents,
                            regdate: info.regdate,
                            isRead: info.isRead,
                            text: info.text,
                            mode: noteView.view,
                            index: index + 1,
                            status: info.isRead === "y" ? "확인" : "미확인",
                          });
                        }}
                      >
                        {info.title}
                      </a>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {ConvertDate(info.regdate)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {info.isRead === "y" ? "확인" : "미확인"}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </>
      );
    };

    const RenderView = () => {

      console.log(this.props)
      if (this.state.mode !== noteView.view) {
        return <></>;
      }
      return (
        <>
          {/* 상세 */}

          <table
            className="table table-bordered"
            style={{ textAlign: "right", color: "white", fontSize: "14px" }}
          >
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "20%" }}>번호</th>
                <th style={{ textAlign: "center", width: "40%" }}>제목</th>
                <th style={{ textAlign: "center", width: "20%" }}>작성일</th>
                <th style={{ textAlign: "center", width: "20%" }}>상태</th>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>{this.state.index}</td>
                <td style={{ textAlign: "center" }}>{this.state.title}</td>
                <td style={{ textAlign: "center" }}>
                  {ConvertDate(this.state.regdate)}
                </td>
                <td style={{ textAlign: "center" }}>{this.state.status}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", height: "400px" }} colSpan={4}>
                  <div
                    style={{ overflow: "auto", width: "100%", height: "100%" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.text }}
                    ></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="hr-line-dashed"></div>
        </>
      );
    };

    return (

      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
      
      <div
              className="modal noticeModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              style={{ display: "block" }}
            >
              <div className="modal-dialog size-md">
                <div className="modal-content">
                  <button
                    className="modal-close-btn modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
              
                  <div className="modal-body">
                    <div className="modal-title">
                      <h5 className="sub">Note</h5>
                      <h2 className="title">쪽지함</h2>
                    </div>
                
                    {RenderList()}
                    {RenderView()}
                  </div>
                </div>
              </div>
            </div>

            {/* <div
              style={{
                //width: "1000px",
                height: "760px",
                background: "#151618",
                color: "#ffffff",
                fontFamily: "'Open Sans', helvetica, arial, sans-serif",
                zIndex: 1000001,
                position: "absolute",
                left: "50%",
                //marginLeft: "-500px",
              }}
              className="container"
            >
              <div className="row" style={{ marginTop: "60px" }}>
                <div className="panel-primary">
                  <div className="panel-body">
                    <form
                      name="joinForm"
                      id="joinForm"
                      className="form-horizontal"
                    >
                      <h2
                        style={{
                          color: "rgb(248, 186, 65)",
                          fontSize: "30px",
                          fontWeight: 400,
                        }}
                      >
                        쪽지
                      </h2>
                      <div className="hr-line-dashed"></div>

                      {RenderList()}
                      {RenderView()}

                      <div className="hr-line-dashed"></div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="pop_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
