import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
  code: number;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  comp = "comp",
  reg = "reg",
  login = "login",
}

export class Deposit extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = { 
      balance: "0",

      code : new Date().getTime(),
    };

  }

  handleAsk = () => {
    this.balanceService.askToAccount().then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "계좌문의에 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "입금",
          message: " 로그인후 이용해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
      }
    });
  };

  handleDoDeposit = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "입금",
        message: "입금금액을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) < 30000) {
      confirmAlert({
        title: "입금",
        message: "최소 입금금액은 삼만원입니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (Number(this.state.balance) % 10000 > 0) {
      confirmAlert({
        title: "입금",
        message: "입금은 만원 단위로 가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.balanceService.applyUserDeposit(this.state.balance, this.state.code).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "입금",
          message: "입금신청을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "wait") {
        confirmAlert({
          title: "입금",
          message: "대기중인 입금신청이 있습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "입금",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="modal depositModal subpage-modal fade show"
              role="dialog"
              data-backdrop="static"
              style={{ display: "block" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <button
                    className="modal-close-btn modal-close"
                    data-dismiss="modal"
                    onClick={() => {
                      this.props.handleClose();
                    }}
                  ></button>
                  <div className="modal-menu">
                    <button
                      className="active"
                      onClick={() => {
                        this.props.handleChange(popupView.deposit);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-money-bill-alt"></i>
                      </div>
                      <p className="kr-text">입금신청</p>
                      <p className="en-text">Deposit</p>
                    </button>
                    <button
                      className="withdraw-link"
                      onClick={() => {
                        this.props.handleChange(popupView.withdraw);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-university"></i>
                      </div>
                      <p className="kr-text">출금신청</p>
                      <p className="en-text">Withdraw</p>
                    </button>
                    <button
                      className="transfer-link"
                      onClick={() => {
                        this.props.handleChange(popupView.point);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-random"></i>
                      </div>
                      <p className="kr-text">금고</p>
                      <p className="en-text">Point</p>
                    </button>
                    <button
                      className="transfer-link"
                      onClick={() => {
                        this.props.handleChange(popupView.comp);
                      }}
                    >
                      <div className="icon">
                        <i className="fas fa-random"></i>
                      </div>
                      <p className="kr-text">콤프</p>
                      <p className="en-text">Comp</p>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-title">
                      <h5 className="sub">Deposit</h5>
                      <h2 className="title">입금신청</h2>
                    </div>
                    <div className="modal-information mb-5">
                      <div className="icon-container">
                        <img src="img/icon/diamond-pink.png" />
                      </div>
                      <div className="text-container text-left">
                        <p>
                          <span className="highlight">입금주의사항 </span>
                        </p>
                        <p>
                          1. 보이스피싱 및 3자사기로 협박하더라도 협상 및 타협
                          절대없음 *
                        </p>
                        <p>
                          2. 계좌거래시 입금/출금 이름이 동일해야 입출금이
                          가능하오니 정확히 입력해 주세요.
                        </p>
                        <p>
                          3. 수표나 타인계좌로 이름변경하여 입금시 머니몰수 및
                          아이디정지 처리되오니 타인명의로 입금해야될시 문의를
                          먼저하시기 바랍니다.
                        </p>
                      </div>
                    </div>
                    <div className="form-container">
                      <div className="form-label">
                        <span className="labels blue">
                          <i className="fa fa-coins"></i> 보유머니 :
                        </span>
                        <span className="amount">
                          {ConverMoeny(this.props.user.balance)}{" "}
                          <span className="symbol">원</span>
                        </span>
                      </div>
                  
                      <div className="form-group">
                        <div className="labels">
                          <span>아이디</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.id}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>예금주명</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.bankowner}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>회원은행</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              value={this.props.user.bankname}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span>신청금액</span>
                          <span className="asterisk">*</span>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <input
                              type="text"
                              readOnly
                              placeholder="0"
                              value={this.state.balance}
                              onFocus={() => this.setState({ balance: "" })}
                              onChange={(e) =>
                                this.setState({
                                  balance: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="infos">
                          <div className="input-container">
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({ balance: "0" });
                              }}
                            >
                              정정
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 1000000
                                  }`,
                                });
                              }}
                            >
                              100만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 500000
                                  }`,
                                });
                              }}
                            >
                              50만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 100000
                                  }`,
                                });
                              }}
                            >
                              10만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 50000
                                  }`,
                                });
                              }}
                            >
                              5만원
                            </button>
                            <button
                              className="form-btn btn-style"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                                width: "120px",
                              }}
                              onClick={() => {
                                this.setState({
                                  balance: `${
                                    Number(this.state.balance) + 10000
                                  }`,
                                });
                              }}
                            >
                              1만원
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn-style"
                        onClick={this.handleDoDeposit}
                      >
                        입금신청
                      </button>
                      <button
                        className="btn-style"
                        onClick={this.handleAsk}
                        style={{backgroundImage :  "linear-gradient(#4caf50,#8bc34a)"}}
                      >
                        계좌문의
                      </button>
                    </div>
                    <p className="py-4 mb-0 mt-5 text-left border-top">
                      * 입금시 꼭 계좌문의를 하세요!{" "}
                      <a
                        className="link"
                        onClick={() => this.handleAsk()}
                      >
                        계좌문의
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
